
          @import "@/theme/jumbleberry/common/var.scss";
          @import "@/theme/clb/common/clb-var.scss";
          @import "@/theme/clb/utilities/clb-mixins.scss";
        














































































































































$--item-size: 132px;

.app-image-grid {
  &__container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
  }

  &__bottom {
    height: 0;
    width: 100%;
  }

  &__bottom-text {
    font-size: 14px;
    line-height: 16px;
    position: relative;
    height: 48px;
    margin-bottom: -48px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

@supports (display: grid) {
  .app-image-grid {
    &__container {
      display: grid;
      grid-column-gap: $--clb-space-3;
      grid-row-gap: $--clb-space-3;

      /* autoprefixer: ignore next */
      grid-template-columns: repeat(auto-fill, minmax($--item-size, auto));
    }

    &__item {
      min-width: 0;
      width: auto;
    }
  }
}
