
          @import "@/theme/jumbleberry/common/var.scss";
          @import "@/theme/clb/common/clb-var.scss";
          @import "@/theme/clb/utilities/clb-mixins.scss";
        































































































































































































































































































.jb-mobile-device {
  .el-table__row {
    pointer-events: none;
  }
}
