
          @import "@/theme/jumbleberry/common/var.scss";
          @import "@/theme/clb/common/clb-var.scss";
          @import "@/theme/clb/utilities/clb-mixins.scss";
        







































































.campaign-reporting {
  &__header {
    display: flex;
    justify-content: space-between;
    margin: 10px 0;

    h3 {
      color: black;
    }

    &-right {
      &-title {
        font-size: 16px;
        font-weight: 400;
      }

      &-body {
        color: $--clb-body-font;
        font-size: 20px;
        font-weight: bold;
        padding-left: $--clb-space-2;
      }
    }
  }

  &__chart-filters {
    display: flex;
    flex-direction: column;
    margin: $--clb-space-4 0;
    flex-shrink: 0;

    .date-picker-filter {
      width: 100%;
      margin-bottom: $--clb-layout-2;

      &__tabs {
        background: $--clb-color-primary__white;
        border: 1px solid #ececec;
      }
    }
  }

  .export-button {
    width: 100%;

    .el-button-group {
      .el-button:first-child {
        width: calc(100% - 50px);
      }

      .el-button:last-child {
        width: 50px;
      }
    }
  }
}

@media (min-width: $--sm) {
  .campaign-reporting {
    .date-picker-filter {
      width: 350px;
      margin: 0;
    }

    &__chart-filters {
      margin: $--clb-layout-3 0;
      justify-content: space-between;
      flex-direction: row;

      .dashboard-filter {
        width: 350px;
        margin-bottom: 0;
      }
    }

    .export-button {
      width: auto;

      .el-button-group {
        .el-button:first-child {
          width: auto;
        }
      }
    }

    .campaign-reporting__table {
      margin-top: $--clb-space-3;
    }

    .campaign-navigation-header__left {
      white-space: nowrap;
    }
  }
}
