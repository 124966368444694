
          @import "@/theme/jumbleberry/common/var.scss";
          @import "@/theme/clb/common/clb-var.scss";
          @import "@/theme/clb/utilities/clb-mixins.scss";
        




















































































.reporting-overview {
  &__skeleton {
    height: calc(#{$--clb-pmv-chart-height-xs} + #{$--clb-pmv-list-height-xs});
  }

  &__visualization {
    border: 1px solid $--clb-border-color-base;
    border-radius: $--clb-border-radius;
    padding-bottom: $--clb-layout-2;
  }
}

@media (min-width: $--xs) {
  .reporting-overview {
    &__skeleton {
      height: calc(#{$--clb-pmv-chart-height-xs} + #{$--clb-pmv-list-height-sm});
    }
  }
}

@media (min-width: $--sm) {
  .reporting-overview {
    &__skeleton {
      height: 360px;
    }

    &__visualization {
      padding: $--clb-layout-2;
    }
  }
}
