
          @import "@/theme/jumbleberry/common/var.scss";
          @import "@/theme/clb/common/clb-var.scss";
          @import "@/theme/clb/utilities/clb-mixins.scss";
        





































.campaign-card-v2 {
  .image-wrapper {
    height: 150px;

    .app-image {
      img {
        @apply tw-rounded;
      }
    }
  }
}
