
          @import "@/theme/jumbleberry/common/var.scss";
          @import "@/theme/clb/common/clb-var.scss";
          @import "@/theme/clb/utilities/clb-mixins.scss";
        

















































































































































































































































































.campaign-setup-marketing-info {
  &-wrapper {
    @media (min-width: 2200px) {
      @apply tw-max-w-screen-2xl tw-mx-auto;
      @apply tw-px-0 #{!important};
    }
  }

  &__header {
    background: linear-gradient(180deg, rgba(64, 128, 225, 0.2) 0%, rgba(248, 249, 253, 1) 100%);
  }

  section {
    @apply tw-mb-10;

    &:last-child {
      @apply tw-mb-0;
    }
  }
}
