
          @import "@/theme/jumbleberry/common/var.scss";
          @import "@/theme/clb/common/clb-var.scss";
          @import "@/theme/clb/utilities/clb-mixins.scss";
        








































































































































.jb-mobile-device {
  .campaign-metrics {
    li {
      transition: none;
    }
  }
}
